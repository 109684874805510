import React, { useEffect, useState } from "react";

import { useRouter } from "next/router";
import { Center, Heading, useToast } from "@chakra-ui/react";

// Graphql
import { useMutation } from "@apollo/client";
import { JOINCLASS } from "../../../../graphql/Mutations/Class";
import { GET_JOINED_CLASSES } from "../../../../graphql/queries/class";

// Recoil
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { classToJoin, authData } from "../../../../State";

// COmponents
import Toast from "../../Toast";

interface addToClassProps {
  toastID?: any;
}

const AddToClass = ({ toastID }: addToClassProps) => {
  const router = useRouter();

  //recoil
  const classToJoinData = useRecoilValue(classToJoin);
  const setStudentData = useSetRecoilState(authData);
  const { loggedIn } = useRecoilValue(authData);

  const toast = useToast();
  const [message, setMessage] = useState("We're adding you to this class...");

  //============================ GRaphql ========================================
  const [studentJoinClass, { loading, data, error }] = useMutation(JOINCLASS, {
    onCompleted({ student_join_class }) {
      const { value, message, student } = student_join_class;
      if (value) {
        // toast({
        // 	position: 'top',
        // 	duration: 7000,
        // 	render: (props) => <Toast title={message} props={props} />
        // });

        toast.update(toastID.current, {
          position: "top",
          duration: 7000,
          render: (props) => <Toast title={message} props={props} />,
        });

        toast.update(toastID.current, {
          position: "top",
          duration: 4000,
          render: (props) => (
            <Toast title="Taking you to your class page" props={props} />
          ),
        });

        setMessage("You've been successfully added to this class");

        setStudentData({
          loggedIn,
          studentData: {
            ...student,
          },
        });

        // router.push(`/classes/enrolled/${classToJoinData._id}`);
        if (typeof window !== "undefined") {
          window.location.href = `/classes/enrolled/${classToJoinData._id}`;
        }
      } else {
        if (message === "You are already enrolled in this class") {
          setMessage("You're already enrolled, Redirecting to class page");
          // toast.update(toastID.current, {
          // 	position: "top",
          // 	duration: 7000,
          // 	render: (props) => <Toast title={message} props={props} />,
          // });
          toast.update(toastID.current, {
            position: "top",
            duration: 3000,
            render: (props) => (
              <Toast
                title="You're already enrolled, Redirecting to class page"
                props={props}
              />
            ),
          });

          // router.push(`/classes/enrolled/${classToJoinData._id}`);
          if (typeof window !== "undefined") {
            window.location.href = `/classes/enrolled/${classToJoinData._id}`;
          }
        } else {
          setMessage(message);
          if (typeof window !== "undefined") {
            window.location.href = `/classes/enrolled`;
          }
        }
      }
    },
    refetchQueries: [{ query: GET_JOINED_CLASSES }],
  });
  // ============================================================================

  useEffect(() => {
    toast.update(toastID.current, {
      position: "top",
      duration: 4000,
      render: (props) => <Toast title={message} props={props} />,
    });

    if (classToJoinData?.referral_link) {
      studentJoinClass({
        variables: {
          class_code: classToJoinData.class_code,
          referral_link: classToJoinData?.referral_link,
        },
      });
    } else {
      studentJoinClass({
        variables: {
          class_code: classToJoinData.class_code,
        },
      });
    }
  }, []);

  return (
    <Center py={20} h="100%" textAlign="center" flexDir="column">
      {/* <Image my="auto" src="./../Logo/LogoIcon.svg" w="38px" /> */}

      <Heading mt={4} fontSize="2xl" fontWeight={900}>
        {message}
      </Heading>
    </Center>
  );
};

export default AddToClass;
