// Graphql
import { useMutation } from "@apollo/client";
// chakra
import { Box, Button, FormLabel, Heading, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
// iCONS
import { HiExclamationCircle } from "react-icons/hi";
// Phone input
import PhoneInput from "react-phone-input-2";
// Yup for validation
import * as Yup from "yup";
import "yup-phone";
import { UPDATE_PHONE_NUMBER } from "../../graphql/Mutations/Student";

const PhoneNumberAfterGoogleSignup = () => {
  const router = useRouter();

  // phone number state
  const [phone_number, setMobileNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState(null);

  // MUTATION to update phone number
  const [updatePhoneNumber, { loading, error }] = useMutation(UPDATE_PHONE_NUMBER, {
    onCompleted({ update_student_profile }) {
      const { value, message } = update_student_profile;
      if (value) {
        router.push(`/discover`);
      } else {
      }
    },
  });

  return (
    <>
      <Box mb={4} w={{ md: "100%" }}>
        <Heading mb={3} fontWeight="900" fontSize="1.75rem" textAlign="center">
          Update Phone Number
        </Heading>

        <Text textAlign="center" mb={10}>
          Your account has been created successfully, please update your phone number
        </Text>

        <FormLabel mb={1} htmlFor="phone number" fontWeight="bold" fontSize="sm">
          Phone Number
        </FormLabel>

        <PhoneInput
          country={"us"}
          // enableSearch={true}
          disableSearchIcon={true}
          value={phone_number}
          onChange={(value) => {
            setMobileNumber(value);

            const phoneSchema = Yup.string().required().min(11);

            (async () => {
              const isValid = await phoneSchema.isValid(value);
              if (!isValid) {
                setPhoneNumberError("Invalid phone number");
              } else {
                setPhoneNumberError(null);
              }
            })();
          }}
        />

        {phoneNumberError && (
          <Box d="flex" mt={2} mb={2} color="red.500" fontWeight="bold" fontSize="sm">
            <Text mr={1} fontSize="lg">
              <HiExclamationCircle />
            </Text>
            <Text>{phoneNumberError}</Text>
          </Box>
        )}
      </Box>

      <Button
        w="100%"
        type="submit"
        fontSize="sm"
        size="lg"
        border="none"
        px={6}
        py={6}
        isDisabled={!phone_number || phoneNumberError}
        // isLoading={loading}
        fontWeight={700}
        colorScheme="blue"
        borderRadius="lg"
        mt={2}
        mr={2}>
        Update Phone Number
      </Button>
    </>
  );
};

export default PhoneNumberAfterGoogleSignup;
