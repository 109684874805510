import { gql } from '@apollo/client';


const UPDATE_PHONE_NUMBER = gql`
	mutation($phone_number: String, $country: String) {
		update_student_profile(
			phone_number: $phone_number
			country: $country
		) {
			message
			value
		}
	}
`;

export {  UPDATE_PHONE_NUMBER };