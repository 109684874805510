// Libraries
import { NextSeo } from "next-seo";
import React, { Fragment, ReactNode } from "react";
// Components
import Navbar from "./UI/Navbar";
interface layoutProps {
  title: any;
  children: ReactNode;
  position?: string;
  description?: string;
  imageURL?: string;
  withNav?: boolean;
}

const Layout = ({ title, children, position, description, withNav = true, imageURL }: layoutProps) => {
  return (
    <Fragment>
      <NextSeo title={title} description={description} canonical="https://learntific.com" />
      {withNav ? (
        <>
          <Navbar position={position} />
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </Fragment>
  );
};
export default Layout;
