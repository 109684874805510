// import Link from 'next/link';
import { HTMLChakraProps, Text } from "@chakra-ui/react";
import React from "react";

interface linkProps {
  title: string;
  fontSize?: string;
  fontWeight?: number;
  onClick?: () => void;
}

const FormLink = ({ title, onClick, fontSize, fontWeight = 900, ...props }: linkProps & HTMLChakraProps<"p">) => {
  return (
    <Text
      borderBottom="1.5px solid #00000033"
      onClick={onClick}
      cursor="pointer"
      fontSize={fontSize}
      transition="all .3s ease"
      bg="white"
      borderRadius="none"
      color="blue.500"
      fontWeight={fontWeight}
      {...props}
      _hover={{ backgroundColor: "blue.50", borderBottomColor: "blue.500" }}>
      {title}
    </Text>
  );
};

export default FormLink;
