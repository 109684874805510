import { gql } from '@apollo/client';

const LOGIN = gql`
	mutation($email: String!, $password: String!) {
		student_login(email: $email, password: $password) {
			message
			value
			action
			student{
				_id
				full_name
				email
				joined_classes{
					class{
						_id
						class_name
						class_code

						lessons{
							_id
							lesson_name
							objectives
							estimated_reading_time
							lesson_content
							no_of_comments
						}
					}
				}

				lesson_completed{
					class{
						class_name
						class_description
					}
					lessons{
						_id
						lesson_name
						
					}
				}

						invitations{
							_id
							class_name
							avatar
							class_description
							class_code
							is_private
						}
			}
		}
	}
`;

const SIGNUP = gql`
	mutation($full_name: String!, $email: String!, $phone_number: String!, $password: String!) {
		student_create_account(full_name: $full_name, email: $email, phone_number: $phone_number, password: $password) {
			message
			value
			action
			student{
				_id
				full_name
				email
				joined_classes{
					class{
						_id
						class_name
						class_code

						lessons{
							_id
							lesson_name
							objectives
							estimated_reading_time
							lesson_content
							no_of_comments
						}
					}
				}

				lesson_completed{
					class{
						class_name
						class_description
					}
					lessons{
						_id
						lesson_name
						
					}
				}

						invitations{
							_id
							class_name
							avatar
							class_description
							class_code
							is_private
						}
			}
		}
	}
`;

const GOOGLEAUTH = gql`
	mutation($name: String!, $email: String!, $googleId: String!, $auth_type: String!) {
		student_social_auth(name: $name, email: $email, googleId: $googleId, auth_type: $auth_type) {
			message
			value
			action
			student{
				_id
				full_name
        email
				joined_classes{
					class{
						_id
						class_name
						class_code

						lessons{
							_id
							lesson_name
							objectives
							estimated_reading_time
							lesson_content
							no_of_comments
						}
					}
				}

				lesson_completed{
					class{
						class_name
						class_description
					}
					lessons{
						_id
						lesson_name
						
					}
				}

						invitations{
							_id
							class_name
							avatar
							class_description
							class_code
							is_private
						}
			}
		}
	}
`;

const RESETPASSWORD = gql`
	mutation($email: String!) {
		student_forgot_password_email(email: $email) {
			message
			value
		}
	}
`;

const CHOOSENEWPASSWORD = gql`
	mutation($pass_token: String!, $email: String!, $new_password: String!, $confirm_password: String!) {
		student_forgot_password(
			pass_token: $pass_token
			email: $email
			new_password: $new_password
			confirm_password: $confirm_password
		) {
			message
			value
		}
	}
`;

export { LOGIN, SIGNUP, GOOGLEAUTH, RESETPASSWORD, CHOOSENEWPASSWORD };
