// Libraries
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { HiExclamationCircle } from "react-icons/hi";
// Components
import FormLink from "./FormLink";

type Props = {
  type: string;
  name?: string;
  label?: string;
  errors: any;
  placeholder?: string;
  reset?: boolean;
  enablebottomMargin?: boolean;
  resetHandler?: () => void;
};

export type Ref = HTMLInputElement;

const FormControlInput = forwardRef<Ref, Props>(
  ({ type, name, label, errors, placeholder, resetHandler, reset, enablebottomMargin = true }, ref) => (
    <FormControl isInvalid={errors} mb={enablebottomMargin ? 4 : 0} id={name}>
      <Flex>
        {label && (
          <FormLabel fontWeight="bold" my="auto" color="gray.500">
            {label}
          </FormLabel>
        )}

        {reset && (
          <Box ml="auto" my="auto">
            <FormLink title="Reset" fontWeight={700} onClick={resetHandler} />
          </Box>
        )}
      </Flex>

      <Input
        mt={1}
        name={name}
        ref={ref}
        fontSize="lg"
        h="48px"
        borderWidth="2"
        borderColor="transparent"
        type={type}
        bg="gray.100"
        borderRadius="4px"
        placeholder={placeholder}
        _placeholder={{ color: "gray.600" }}
      />

      {errors && (
        <FormErrorMessage d="flex" fontWeight="bold" fontSize="sm">
          <Text mr={1} fontSize="lg">
            <HiExclamationCircle />
          </Text>
          <Text>{errors.message || "Something happened"}</Text>
        </FormErrorMessage>
      )}
    </FormControl>
  )
);

export const DarkFormControlInput = forwardRef<Ref, Props>(
  ({ type, name, label, errors, placeholder, resetHandler, reset, enablebottomMargin = true }, ref) => (
    <FormControl isInvalid={errors} mb={enablebottomMargin ? 4 : 0} id={name}>
      <Flex>
        {label && (
          <FormLabel fontWeight="bold" my="auto" color="blue.100">
            {label}
          </FormLabel>
        )}

        {reset && (
          <Box ml="auto" my="auto">
            <FormLink bg="blue.600" color="white" borderBottomColor="white" title="Reset" fontWeight={700} onClick={resetHandler} />
          </Box>
        )}
      </Flex>

      <Input
        mt={1}
        name={name}
        ref={ref}
        fontSize="lg"
        h="48px"
        borderWidth="2"
        borderColor="transparent"
        type={type}
        color="white"
        bg="blue.400"
        borderRadius="4px"
        fontWeight="500"
        placeholder={placeholder}
        _focus={{ boxShadow: "0 0 0 1px #fff", borderColor: "white" }}
        _placeholder={{ color: "blue.200" }}
      />

      {errors && (
        <FormErrorMessage color="green.500" d="flex" fontWeight="bold" fontSize="sm">
          <Text mr={1} fontSize="lg">
            <HiExclamationCircle />
          </Text>
          <Text>{errors.message || "Something happened"}</Text>
        </FormErrorMessage>
      )}
    </FormControl>
  )
);

export default FormControlInput;
