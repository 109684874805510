// Graphql
import { useMutation } from "@apollo/client";
// chakra
import { Box, Button, Flex, Heading, SimpleGrid, Text, useToast } from "@chakra-ui/react";
// firebase
import firebase from "firebase/app";
import "firebase/auth";
// js cookie
import Cookie from "js-cookie";
import { useRouter } from "next/router";
import React, { Fragment, useState } from "react";
import { GoogleLogin, useGoogleLogout } from "react-google-login";
// react hookform
import { useForm } from "react-hook-form";
// iCONS
import { HiExclamationCircle } from "react-icons/hi";
// Recoil
import { useSetRecoilState } from "recoil";
import "yup-phone";
import { GOOGLEAUTH, SIGNUP } from "../../graphql/Mutations/Auth";
import { authData } from "../../State";
// Components
import { DarkFormControlInput } from "../UI/Form/FormControlInput";
import FormLink from "../UI/Form/FormLink";
// import ClassPassword from "../Enroll/ClassPassword";
import AddToClass from "../UI/Modal/Enroll/AddToClass";
import Toast from "../UI/Toast";
import PhoneNumberAfterGoogleSignup from "./PhoneNumberAfterGoogleSignup";

interface signUpProps {
  joinAClass: boolean;
  justLogin: boolean;
  setShowSignup?: any;
  setLoginV?: any;
  //this is used to check if we are actually in signup page or we are using the signup component
  isPage: boolean;
}

const Signup = ({ joinAClass, justLogin, isPage, setShowSignup, setLoginV: sLV }: signUpProps) => {
  const router = useRouter();

  // Recoil
  // const classToJoinData = useRecoilValue(classToJoin);
  const setAuthValue = useSetRecoilState(authData);

  // toast
  const toast = useToast();
  const toastID = React.useRef<any>(null);

  // React hook form initialisation, refer to https://react-hook-form.com/get-started for clarification
  const { register, handleSubmit, errors } = useForm();

  // STate
  const [errorMessage, setErrorMessage] = useState("");

  const [phone_number, setMobileNumber] = useState("");
  // STate
  const [googleErrorMessage, setGoogleErrorMessage] = useState("");
  const [addToClassH, setAddToClass] = useState(false);
  const [loginV, setLoginV] = useState(true);
  const [showPhoneNumberInputAfterGoogleSignup, setshowPhoneNumberInputAfterGoogleSignup] = useState(false);
  // const [classPasswordV, setClassPasswordV] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(null);

  const [profileObject, setProfileObject] = useState({});
  const [googleIdentification, setGoogleIdentification] = useState("");

  const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
  const { signOut } = useGoogleLogout({
    clientId,
  });

  //============================ GRaphql ========================================
  const [studentSignup, { loading }] = useMutation(SIGNUP, {
    onCompleted({ student_create_account }) {
      const { value, message, student } = student_create_account;

      if (value) {
        firebase.auth().signInWithEmailAndPassword("noreply@coyani.com", "Coyani2020");
        Cookie.set("x-auth-student", student);
        Cookie.set("student_email", student.email);
        Cookie.set("student_name", student.full_name);

        Cookie.set("student_id", student._id);
        Cookie.set("x-auth-type", "email");
        Cookie.set("x-auth-token", message);

        setAuthValue({
          loggedIn: true,
          studentData: student,
        });

        if (justLogin) {
          // router.push(`/classes/enrolled`);
          if (student?.joined_classes.length > 0) {
            window.location.href = "/classes/enrolled";
          } else {
            window.location.href = "/discover";
          }
          toastID.current = toast({
            position: "top",
            duration: 7000,
            render: (props) => <Toast title="Signed up successfully" props={props} />,
          });
        }

        if (joinAClass) {
          // if (classToJoinData.is_private) {
          //   setClassPasswordV(true);
          //   setLoginV(false);
          // } else {
          //   setAddToClass(true);
          //   setLoginV(false);
          // }
          setAddToClass(true);
          setLoginV(false);
        }
      } else {
        setErrorMessage(message);
      }
    },
  });

  const loginHandler = async (profileObj, googleId) => {
    toast.update(toastID.current, {
      position: "top",
      duration: 7000,
      render: (props) => <Toast title="Logging you in..." props={props} />,
    });

    try {
      await googleLogin({
        variables: {
          name: profileObj.name,
          googleId: googleId,
          email: profileObj.email,
          auth_type: "STUDENT_LOGIN",
        },
      });
    } catch (err) {
      toast.update(toastID.current, {
        position: "top",
        duration: 7000,
        render: (props) => <Toast title={err.message} props={props} />,
      });

      return null;
    }
  };

  const [googleLogin, { loading: googleLoginLoading }] = useMutation(GOOGLEAUTH, {
    onCompleted({ student_social_auth }) {
      const { value, message, student, action } = student_social_auth;

      if (value) {
        firebase.auth().signInWithEmailAndPassword("noreply@coyani.com", "Coyani2020");
        setAuthValue({
          loggedIn: true,
          studentData: student,
        });
        Cookie.set("x-auth-student", student);
        Cookie.set("student_email", student.email);
        Cookie.set("student_name", student.full_name);

        Cookie.set("student_id", student._id);
        Cookie.set("x-auth-token", message);

        Cookie.set("x-auth-type", "google");

        if (justLogin) {
          // router.push(`/classes/enrolled`);
          window.location.href = "/classes/enrolled";
          toast.update(toastID.current, {
            position: "top",
            duration: 7000,
            render: (props) => <Toast title="Logged in successfully" props={props} />,
          });
        }

        if (joinAClass) {
          // if (classToJoinData.is_private) {
          //   setClassPasswordV(true);
          //   setLoginV(false);
          // } else {
          //   // disclosure.onClose();
          //   // setTrack("tracking");
          //   setAddToClass(true);
          //   setLoginV(false);
          // }
          setAddToClass(true);
          setLoginV(false);
        }
      } else {
        toast.update(toastID.current, {
          position: "top",
          duration: 7000,
          render: (props) => <Toast title={message} props={props} />,
        });

        setGoogleErrorMessage(message);

        signOut();
        firebase.auth().signOut();
      }
    },
  });

  const [googleSignup, { loading: googleLoading }] = useMutation(GOOGLEAUTH, {
    onCompleted({ student_social_auth }) {
      const { value, message, student, action } = student_social_auth;

      if (value) {
        firebase.auth().signInWithEmailAndPassword("noreply@coyani.com", "Coyani2020");
        Cookie.set("x-auth-token", message);

        Cookie.set("x-auth-student", student);
        Cookie.set("student_email", student.email);
        Cookie.set("student_name", student.full_name);

        Cookie.set("student_id", student._id);
        Cookie.set("x-auth-type", "google");

        setAuthValue({
          loggedIn: true,
          studentData: student,
        });
        // localStorage.setItem("x-auth-user", JSON.stringify(response.author));

        if (justLogin) {
          toast.update(toastID.current, {
            position: "top",
            duration: 7000,
            render: (props) => <Toast title="Signed up successfully" props={props} />,
          });

          // setLoginV(false);
          // setshowPhoneNumberInputAfterGoogleSignup(true);
          // router.push(`/classes/enrolled`);
          window.location.href = "/classes/enrolled";
        }

        if (joinAClass) {
          // if (classToJoinData.is_private) {
          //   setClassPasswordV(true);
          //   setLoginV(false);
          // } else {
          //   setAddToClass(true);
          //   setLoginV(false);
          // }
          setAddToClass(true);
          setLoginV(false);
        }
      } else {
        if (action === "Existing account found") {
          loginHandler(profileObject, googleIdentification);
        } else {
          signOut();
          firebase.auth().signOut();
          toast.update(toastID.current, {
            position: "top",
            duration: 7000,
            render: (props) => <Toast title={message} props={props} />,
          });
          setGoogleErrorMessage(message);
        }
      }
    },
  });

  // ============================================================================

  // Onsubmit function
  const onSubmit = async (data) => {
    try {
      await studentSignup({
        variables: {
          ...data,
          // phone_number,
        },
      });
    } catch (error) {
      //   console.log(error);
    }
  };

  const REDIRECT_URI =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "https://learntific.vercel.app/signup"
      : "https://learntific.com/signup";

  const responseGoogle = async (response: any) => {
    const { googleId, profileObj, error } = response;

    if (googleId) {
      toastID.current = toast({
        position: "top",
        duration: 7000,
        render: (props) => (
          <Toast
            title={!error ? `Authenticated as ${profileObj.name}` : "We're unable to authenticate with your Google Account"}
            props={props}
          />
        ),
      });
    }

    if (!error) {
      setGoogleIdentification(googleId);
      setProfileObject(profileObj);
      try {
        await googleSignup({
          variables: {
            name: profileObj.name,
            googleId: googleId,
            email: profileObj.email,
            auth_type: "STUDENT_SIGNUP",
          },
        });
      } catch (err) {
        toast.update(toastID.current, {
          position: "top",
          duration: 7000,
          render: (props) => <Toast title="Something happened, please try again" props={props} />,
        });
        signOut();
        firebase.auth().signOut();
        return null;
      }
    }

    // name, googleId, email, authtype
    // alert('done');
  };

  return (
    <Fragment>
      {/*  */}
      <Box px={{ md: 10 }} mt={10}>
        {loginV && (
          <Fragment>
            {/* Heading */}
            <Heading mb={3} fontWeight="900" fontSize={{ base: "2xl", md: "1.75rem" }} textAlign="center" color="white">
              Create a Learntific Account
            </Heading>

            {/* Google Signup*/}
            <GoogleLogin
              clientId={clientId}
              buttonText="Signup"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              uxMode="redirect"
              isSignedIn={true}
              redirectUri={`${REDIRECT_URI}`}
              render={(renderProps) => (
                <Button
                  fontSize="sm"
                  w="100%"
                  size="lg"
                  isLoading={googleLoading || googleLoginLoading}
                  onClick={() => {
                    renderProps.onClick();
                    setGoogleErrorMessage(null);
                  }}
                  px={6}
                  fontWeight={700}
                  colorScheme="green"
                  color="blue.500"
                  borderRadius="lg"
                  // isLoading={loading}
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4 10C4 13.3137 6.68629 16 10 16C12.6124 16 14.8349 14.3304 15.6586 12H10V8H19.8047V12H19.8C18.8734 16.5645 14.8379 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C13.445 0 16.4831 1.742 18.2815 4.39318L15.0039 6.68815C13.9296 5.06812 12.0895 4 10 4C6.68629 4 4 6.68629 4 10Z"
                      fill="currentColor"
                    />
                  </svg>

                  <Text as="span" ml={2}>
                    Sign up with Google
                  </Text>
                </Button>
              )}
            />

            {googleErrorMessage && (
              <Box justifyContent="center" mt={2} d="flex" mb={2} color="green.500" fontWeight="bold" fontSize="sm">
                <Text mr={1} fontSize="lg">
                  <HiExclamationCircle />
                </Text>
                <Text>{googleErrorMessage}</Text>
              </Box>
            )}

            <Text textAlign="center" fontWeight="700" color="gray.400" my={4}>
              or
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleGrid columns={[1, 2]} spacing="10px">
                {/* Email */}
                <Box w={{ md: "100%" }}>
                  <DarkFormControlInput
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="hilda@email.com"
                    errors={errors.email}
                    ref={register({
                      required: "We need your email to create your account.",
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Your email dosen't look right",
                      },
                    })}
                  />
                </Box>

                {/* Password */}
                <Box w={{ md: "100%" }}>
                  <DarkFormControlInput
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="•••••••••"
                    errors={errors.password}
                    ref={register({
                      required: "Provide a password to protect your account.",
                    })}
                  />
                </Box>

                {/* Fullname */}
                <Box w={{ md: "100%" }}>
                  <DarkFormControlInput
                    type="text"
                    name="full_name"
                    label="Fullname"
                    placeholder="Hild Mckensie"
                    errors={errors.full_name}
                    ref={register({
                      required: "We need to know how to address you.",
                    })}
                  />
                </Box>

                <Box mb={{ base: 8, md: 0 }} w={{ md: "100%" }}>
                  <DarkFormControlInput
                    type="text"
                    name="phone_number"
                    label="Phone Number"
                    placeholder="+108143113800"
                    errors={errors.phone_number}
                    ref={register({
                      required: "Provide a valid phone number to prevent lockout from account",
                    })}
                  />

                  {/* <PhoneInput
                    country={"us"}
                    // enableSearch={true}
                    disableSearchIcon={true}
                    value={phone_number}
                    onChange={(value) => {
                      setMobileNumber(value);

                      const phoneSchema = Yup.string().required().min(11);

                      (async () => {
                        const isValid = await phoneSchema.isValid(value);
                        if (!isValid) {
                          setPhoneNumberError("Invalid phone number");
                        } else {
                          setPhoneNumberError(null);
                        }
                      })();
                    }}
                  /> */}

                  {/* {phoneNumberError && (
                    <Box d="flex" mt={2} mb={2} color="green.500" fontWeight="bold" fontSize="sm">
                      <Text mr={1} fontSize="lg">
                        <HiExclamationCircle />
                      </Text>
                      <Text>{phoneNumberError}</Text>
                    </Box>
                  )} */}
                </Box>
              </SimpleGrid>

              {errorMessage && (
                <Box d="flex" mb={2} color="green.500" fontWeight="bold" fontSize="sm">
                  <Text mr={1} fontSize="lg">
                    <HiExclamationCircle />
                  </Text>
                  <Text>{errorMessage}</Text>
                </Box>
              )}

              <Button
                mt={2}
                w="100%"
                type="submit"
                colorScheme="gray"
                variant="solid"
                fontSize="sm"
                size="lg"
                isLoading={loading}
                px={6}
                py={6}
                onClick={() => {
                  setErrorMessage(null);
                }}
                fontWeight={700}
                borderRadius="lg"
                mb={4}>
                Sign up with Email
              </Button>
            </form>
            <Flex justify="center" mt={{ lg: 8 }} mb={{ base: 8, md: 0 }}>
              <FormLink
                bg="blue.600"
                color="white"
                borderBottomColor="white"
                fontWeight={700}
                onClick={
                  isPage
                    ? () => {
                        router.push("/login");
                      }
                    : () => {
                        setShowSignup(false);
                        sLV(true);
                      }
                }
                title="I already have an account"
              />
            </Flex>
          </Fragment>
        )}

        {showPhoneNumberInputAfterGoogleSignup && <PhoneNumberAfterGoogleSignup />}

        {/* Add to class functionality */}
        {addToClassH && <AddToClass toastID={toastID} />}
        {/* {classPasswordV && <ClassPassword fromAuth={true} />} */}
      </Box>
    </Fragment>
  );
};

export default Signup;
